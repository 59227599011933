import { http } from '@/lib/http'
import { EntitySourceListType } from '#/AccountsTypes'
import { DashboardDataType, AccountMappingItemType } from '#/ReportsTypes'
import { ComputeEventParamsType, ComputeAccountParamsType, ComputeAggregationParamsType } from '#/FinancialsTypes'

export default {
  /**
   * 获取报表配置
   * @param {string} entityId 主体id
   */
  getReportConfig: (entityId: string) => {
    return http.request('get', `/entity/${entityId}/reportConfig`)
  },
  /**
   * 获取balance-sheet
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} period 期间 // MONTHLY/WEEK/DAY
   * @property {string[]} dateRange 日期范围 // [2021-01-01,2021-01-31]
   */
  getBalanceSheet: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/balance-sheet`, { params })
  },
  /**
   * 获取income-statement
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.period 期间 // MONTHLY/WEEK/DAY
   * @property {string[]} data.dateRange 日期范围 // [2021-01-01,2021-01-31]
   * @property {object} data.group 辅助核算字段对象
   * @property {string} data.group.auxiliaryTypeId 辅助核算字段Id
   * @property {object} data.subGroup 子辅助核算字段对象
   * @property {string} data.subGroup.auxiliaryTypeId 子辅助核算字段Id
   */
  getIncomeStatement: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/income-statement`, { data })
  },
  /**
   * 获取cash-flow-statement
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} period 期间 // MONTHLY/WEEK/DAY
   * @property {string[]} dateRange 日期范围 // [2021-01-01,2021-01-31]
   * @returns
   */
  getCashFlowStatement: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/cash-flow-statement`, { params })
  },
  /**
   * 获取restrictions-of-crypto-assets
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} period 期间 // MONTHLY/WEEK/DAY
   * @property {string[]} dateRange 日期范围 // [2021-01-01,2021-01-31]
   * @returns
   */
  getRestrictionsOfCryptoAssets: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/restrictions-of-crypto-assets`, { params })
  },
  /**
   * 获取crypto-assets-roll-forward
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} period 期间 // MONTHLY/WEEK/DAY
   * @property {string[]} dateRange 日期范围 // [2021-01-01,2021-01-31]
   * @returns
   */
  getCryptoAssetsRollForward: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/crypto-assets-rollforward`, { params })
  },
  /**
   * 获取significant-holdings
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} date 日期范围 // 2021-01-01
   * @returns
   */
  getSignificantHoldings: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/significant-holdings`, { params })
  },
  /**
   * 获取计算弹窗的公式数据
   * @param {string} entityId
   * @param {string} name
   * @param {Object} data
   * @property {string} fieldId
   */
  getComputeAggregationApi: (entityId: string, name: string, data: ComputeAggregationParamsType) => {
    return http.request('post', `/entity/${entityId}/${name}/formula`, { data })
  },
  /**
   * 获取计算弹窗的科目第一个表格数据
   * @param {string} entityId
   * @param {ComputeAccountParamsType} data
   * @returns
   */
  getComputeAccountApi: (entityId: string, data: ComputeAccountParamsType) => {
    return http.request('post', `/entity/${entityId}/account/note/l1`, {
      data
    })
  },
  /**
   * 获取计算弹窗的科目第二个表格数据
   * @param {string} entityId
   * @param {ComputeEventParamsType} data
   * @returns
   */
  getComputeEventApi: (entityId: string, data: ComputeEventParamsType) => {
    return http.request('post', `/entity/${entityId}/account/note/l2`, {
      data
    })
  },
  /**
   * 获取general-ledger列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} accountId fieldId
   * @property {number} page 页码
   * @property {number} limit 每页条数
   * @returns
   */
  getGeneralLedgerList: (entityId: string, params: any) => {
    return http.request('get', `/entity/${entityId}/general-ledger/list`, { params })
  },
  /**
   * 快捷批量修改业务活动标签
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string[]} activityIds 交易ids
   * @property {string} categoryNo? 业务活动标签id
   */
  quickEditEvent: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/batch/activity`, { data })
  },
  /**
   * 重新生成报表
   * @param {string} entityId 主体id
   */
  getRegenerateReport: (entityId: string) => {
    return http.request('get', `/entity/${entityId}/regenerate-report`)
  },
  /**
   * 获取Entity授权erp
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {number} code // 回调后的code
   * @property {string} redirectUri // 回调地址
   * @property {string} type // erp类型 XERO
   */
  getEntityAuthErp: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/integration/access`, { data })
  },
  /**
   * 取消Entity授权erp
   * @param {string} entityId 主体id
   */
  cancelEntityAuthErp: (entityId: string) => {
    return http.request('delete', `/entity/${entityId}/integration/access/cancel`)
  },
  /**
   * 获取科目映射列表
   * @param {string} entityId 主体id
   */
  getAccountMappingList: (entityId: string) => {
    return http.request('get', `/entity/${entityId}/integration/account-mapping`)
  },
  /**
   * 科目映射修改
   * @param {string} entityId 主体id
   * @param {Array} data
   */
  editAccountMapping: (entityId: string, data: object) => {
    return http.request<ResponseData<AccountMappingItemType>>(
      'put',
      `/entity/${entityId}/integration/account-mapping`,
      { data }
    )
  },
  /**
   * 同步分录ERP
   * @param {string} entityId 主体id
   */
  syncJournalIntegration: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/integration/sync-journal-mapping`)
  },
  /**
   * 获取dashboard详情
   * @param {string} entityId 主体id
   */
  getDashboardDetail: (entityId: string) => {
    return http.request<ResponseData<DashboardDataType>>('get', `/entity/${entityId}/dashboard`)
  },
  /**
   * 获取entity全部source
   * @param {string} entityId 主体id
   */
  getEntitySourceList: (entityId: string) => {
    return http.request<ResponseData<EntitySourceListType>>('get', `/entity/${entityId}/source/all`)
  }
}
